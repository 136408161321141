.CalendarDay__selected {
    background: #484848 !important; 
    color: white !important;
    border-radius: 50%;
}

.CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
    background: rgb(243, 243, 243) !important;
    color: $clrBLACK !important;
    border: 0px double $clrGRAY !important;
}

.arrow{
    display: flex;
    align-items: center;
}

.CalendarDay__selected_span {
    background: rgb(243, 243, 243) !important; 
    color: $clrBLACK !important;
}

.CalendarDay__default {
    border: 0px solid $clrGRAY !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before { //?
    border-right: 33px solid $clrRED !important;
}

.DateRangePickerInput_arrow_svg { //arrow
    display: none !important;
}

.DayPickerKeyboardShortcuts_show__topRight::before {
    border-right: 33px solid $clrRED !important;
}