.user-msg {
    color:white;
    padding: 10px;
    font-size: 1.4em;
    position: fixed;
    top:0;
    right:0;
    background-color: grey;
}
.user-msg button {
    float:right;
    border:0;
    border-radius: 50%;
    margin: 5px;
    background-color: #222;
}

.user-msg.success {
    background-color: $clrRED;
    border-bottom-left-radius: 12px;
    z-index: 10000;
}
.user-msg.danger {
    background-color: rgb(216, 59, 59);
    z-index: 10000;
}
