
.hidden{
    top: -50%;
    left: -50%;
   
}

.content-host{
    margin-top: 115px;
}

.main-upload-stay {

    span{
        float: right;
        cursor: pointer;
    }


    h1:first-child{
        text-align: center;
        margin-bottom: 2rem;
    }
     
    // top: 50%;
    // left: 50%;
    // position: fixed;
    // background-color: lawngreen;
    
    // transform: translate(-50%, -50%);
    z-index: 110;
    background-color: $bgc;
    width: 100%;

    .upload-btn-container{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    // width: 80%;
    .reserve-button{
        margin: 0 auto;
        align-self: center;
        width: 20rem;
    }
    border-radius: 12px;
    padding: 1rem;
    z-index: 110;
    input{
        margin: 1rem;

    }
    // display: block;

    
    .upload-preview {
        // padding: 0.5rem;
        width: 10rem;
        height: 10rem;
        display: flex;
        
        
        img:first-child{
            
            aspect-ratio: 2/1;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
        }
        // img:nth-child(4){
            
        //     aspect-ratio: 2/1;
        //     border-bottom-left-radius: 12px;
        //     // border-top-right-radius: 12px;
        // }
        
        // img:not:first-child {
            //     aspect-ratio: 1/1;
            
            // }
            
            img{
                width: 100% !important;
                object-fit: cover;
                float: unset !important;
                border-radius: unset !important;
                max-width: unset !important;
            }
            
            
        }
        
        li:first-child{

            .upload-preview:first-child{
                width: 100%;
                input{
                    width: 100%;
                }
        }
    
        }
        img {
            // width: 1rem !important;
        }
        
    .img-upload-gallery {
        box-shadow:  0px 2px 14px -2px rgb(0 0 0 / 85%);
        border-radius: 12px;
       
        margin-bottom: 1rem;
        gap: 0.5rem;
        // input{
        //     width: 1rem !important;
        // }


        display: grid;
        grid-template-areas:
            "a a"
            "b c"
            "d e";


        li:nth-child(1) {
            grid-area: a;
            img, .upload-preview{
                border-top-left-radius: 12px !important ;
                border-top-right-radius: 12px !important ;
            }
            
        }
        
        li:nth-child(2) {
            grid-area: b;
            
        }
        
        li:nth-child(3) {
            grid-area: c;
            
        }
        
        li:nth-child(4) {
            grid-area: d;
            img, .input{

                border-bottom-left-radius: 12px !important ;
            }
            
        }
        
        li:nth-child(5) {
            grid-area: e;

            img, .input{

                border-bottom-right-radius: 12px !important ;
                
            }

        }

    }

    .container {
        display: flex;
        justify-content: space-evenly;

        .details-form{
            .MuiOutlinedInput-root{
                height: 3rem;
                margin: 0.5rem;
            }
        }



        .details-form,
        .checkboxes {
            h1{
                text-align: left;

            }
            display: flex;
            flex-direction: column;

            input {
                padding: 1rem;
                margin: 0.5rem;
            }
        }
    }
}