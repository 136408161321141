.gallery-grid{
    display: grid;
    grid-template-areas: 
    "a a b c"
    "a a d e";

    :first-child{
        grid-area: a;
        border-top-left-radius: 12px;
        border-bottom-left-radius : 12px;
    }

    :nth-child(2){
        grid-area: b;
    }
    :nth-child(3){
        grid-area: c;
        border-top-right-radius: 12px;
    }
    :nth-child(4){
        grid-area: d;
    }
    :nth-child(5){
        grid-area: e;
        border-bottom-right-radius : 12px;
    }

    img{
        width: 100%;
        height: 100%;
        aspect-ratio:1/1;
        object-fit: cover;
    }
    
}

.img-modal{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 95vw !important;
    height: unset !important;
    border-radius: 20px;
    z-index: 101;
}




