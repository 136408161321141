
.main-guest-qty-container{
    padding: 20px;
    // margin-top: 1rem;
    // padding-left: 20px;
    // padding-right: 20px;
    
    .between-btns{
        width: 1rem;
    }
    
    .adults{
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;
    }
    
    .adults-right{
        display: flex;
        gap: 5px;
        
        .guests-pick{
            cursor: pointer;
        }
        div{
            // width: 20px;
        }


        span{
            border: 1px grey solid;
            border-radius: 50%;
            text-align: center;
            cursor: pointer;
            
        }
    }
}

