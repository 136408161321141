.filterr {
     display: flex;
     justify-content: center;
     align-items: center;
     width: 100%;
     z-index: 10;
     padding-bottom: 5px;

     input {
          outline: none;
          border: 0px;
          background-color: unset;
     }

     .inpt-fillter:first-child {
          input {
               margin-left: 10px;
          }
     }

     .inpt-fillter {
          height: 100%;
          display: flex;
          align-self: center;
          cursor: pointer;
          text-decoration: none;
     }

     .navlink {
          text-decoration: inherit;
          text-decoration: none;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          div {
               padding: 0.5rem;
          }
     }
}

.small {
     .navlink {
          div {
               padding: 0.3rem;
          }
     }
}

.search-symbol {
     cursor: pointer;
     border-radius: 50%;
     background-color: $clrRED;
     color: white;
     height: 100%;
     display: flex;
     text-decoration: inherit;
}

.white {
     text-decoration: none;
     text-decoration: inherit;
     color: white;
}

.total-filter {
     padding-left: 1rem;
     display: flex;
     box-shadow: inset 0 0 0 1px lightgray;
     background-color: $bgc;
     border-radius: 42px;

     &:hover {
          box-sizing: border-box;
          box-shadow: inset 0 0 0 2px lightgray;
     }
     
}
.total-filter-small {
     padding-left: 1rem;
     display: flex;
     box-shadow: inset 0 0 0 1px lightgray;
     background-color: $bgc;
     border-radius: 42px;
     justify-content: space-between;

     &:hover {
          box-sizing: border-box;
          box-shadow: inset 0 0 0 2px lightgray;
     }
}

.main-filter-label {
     margin-left: 10px;
     font-size: 12px !important;
}

.filter-labels-holder{
     width: 260px;
     display: flex;
     gap: 71px;
     &:nth-child(1){
          margin-left: 39px;
     }
}