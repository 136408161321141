/* MOBILE ONLY  */

@media (max-width: 640px) {

    :first-child.filterr .inpt-fillter{
        width: 100%;
        input{
            width: 100%;
        }
    }

    // .total-filter-small{
    //     height: 40px;
    // }

    .main-nav {
        width: 85%;
        margin: auto;
        display: unset;
        margin-top: 1rem;
        height: unset;
    }

    .gallery-grid {
        gap: 2px;
    }

    .filterr {
        display: unset;

        > div {
            display: unset;
        }

        .total-filter {
            justify-content: space-between;
            flex-direction: column;
            gap: 1rem;
            padding-left: 0;
            box-shadow: unset;
            border-radius: 0;
            width: 100%;
            position: fixed;
            left: 0;
            top: 0;
            box-shadow: 0px 5px 15px -3px rgb(36 36 36 / 80%);

            .inpt-fillter:first-child {
                align-self: center;
                margin-top: 10px;
            }

            .filter-labels-holder {
                margin-left: unset;

                .main-filter-label {
                    margin-left: 0;
                }
            }

            .filter-labels-holder {
                width: 100%;
                justify-content: space-around;
                gap: unset;
            }
        }
    }

    .right-side-trip {
        display: none;
    }

    .left-side-trip {
        grid-column: 1/-1;
        border-radius: 12px;
        border: 1px lightgray solid;
    }

    .secondary-filter {
        margin-top: 75px;
    }

    .hidden-from-mobile {
        display: none !important;
        visibility: hidden;
    }

    .stock-margin,
    .stock-margin-narrow {
        grid-template-columns: 10px 1fr 10px;
    }

    .card-container {
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }

    .secondary-header-stay-details {
        flex-direction: column;
    }

    .footer-desktop {
        visibility: hidden;
    }

    .nav-link-parent {
        display: none;
    }

    .small-filte-parent {
        pointer-events: none;

        input {
            pointer-events: all;
        }
    }

    .logo {
        display: none;
    }

    .mobile-header {
        display: unset !important;
        padding: 0.5rem;

        .right,
        .left {
            display: none;
        }

        .stock-margin-center {
            grid-column: unset !important;
        }

        .flex-col {
            display: unset;
        }

        .main-nav {
            display: unset !important;
        }

        .small {
            display: unset !important;
        }

        .total-filter {
            justify-content: space-between;
        }
    }

    .become-a-host {
        height: 300px !important;
        margin-top: 0.5rem !important;
        background-position: 54% !important;
        h1 {
            max-width: 150px;
            font-size: 2.25rem !important;
        }
        button {
            margin-top: 15px !important;
        }
    }

    .host-modal-fullscreen {
        h1 {
            font-size: 2rem;
        }
        .right-side {
            display: none !important;
        }
    }

    .main-page-head {
        height: 40vh;
    }

    .home-page-card-container {
        margin-top: 0;
    }

    .home-page-card-container .home-page-card-line {
        grid-template-columns: 1fr 1fr;
    }

    .user-menu-container {
        position: fixed;
        bottom: 40px;
        border-radius: 0;
        box-shadow: unset;
        width: 100%;
        > li {
            border-top: 1px solid $clrGRAY;
        }
    }

    .form-container {
        width: 320px;

        p {
            display: none;
        }
    }

    .reserve-stay-container {
        position: fixed;
        display: flex;
        top: 0;
        bottom: 44px;
        border-radius: unset;
        box-shadow: unset;
        width: 100%;
        left: 0;
        bottom: 10px;
        gap: 1rem;
        border: unset;
        z-index: 101;
    }

    .res-confirmed-modal {
        width: 100%;
        height: 100vh;
        z-index: 100;
    }

    .main-upload-stay{
        a:last-child{
            width: 100%;

            button{
                width: 100%;
            }
        }
    }

    .main-upload-stay .container{
        flex-direction: column;
    }

    .statistics-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .reservations-container {
        table{
            display:none
        }
    }

    .reservations-mobile{
        display: block !important;
    }
}

// tablet
@media (min-width: 640px) {

    .main-footer{
        *{

             font-size: 0.9em !important;
        }
    }

    .main-upload-stay .container{
        flex-direction: column;
    }

    .gallery-grid {
        gap: 8px;
        --ResVisible: none;
    }

    .hidden-from-tablet2 {
        display: none;
        visibility: hidden;
    }

    .host-modal-fullscreen {
        h1 {
            font-size: 3rem;
        }
    }

    .trip-cards-container {
        grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    }

    .left-side-trip {
        grid-column: 1;
    }

    .main-nav {
        .logo {
            display: none;
        }
    }

    .secondary-filter {
        margin-top: 170px;
    }

    .main-reviews {
        grid-template-columns: repeat(2, minmax(auto, 1fr));
    }

    .stock-margin {
        grid-template-columns: 60px 1fr 60px;
    }

    .card-container {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }

    .mobile {
        display: none;
        visibility: hidden;
    }

    .footer-mobile {
        visibility: hidden;
    }

    .mobile-header {
        visibility: hidden;
        grid-column: 2;
    }
}

// /* LARGE */

@media (min-width: 920px) {

    .main-footer{
        *{

             font-size: 1em !important;
        }
    }

    .main-upload-stay .container{
        flex-direction: row;
    }
    .hidden-from-tablet {
        display: unset;
        visibility: visible;
    }

    .main-nav {
        .logo {
            display: -webkit-box;
        }
    }

    .secondary-filter {
        margin-top: 170px;
    }

    .main-reviews {
        grid-template-columns: repeat(2, minmax(auto, 1fr));
    }

    .stay-bellow-container {
        grid-template-columns: 3fr 1fr;
    }

    .features-container {
        padding-right: 15%;
        flex: 80% 1;
    }

    .stock-margin {
        grid-template-columns: 60px 1fr 60px;
    }

    .card-container {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }

    .mobile {
        display: none;
        visibility: hidden;
    }

    .footer-mobile {
        visibility: hidden;
    }

    .mobile-header {
        visibility: hidden;
        grid-column: 2;
    }
}
