.main-host-page {
    .add-stay-btn {
        span {
            font-size: 4rem;
            cursor: pointer;
            border: 0.1px black solid;
            border-radius: 8px;
        }
    }

    section,
    .reservations-container {
        > h1 {
            margin-top: 5rem;
            font-size: 2em;
        }
    }

    .host-modal-fullscreen {
        display: flex;

        .right-side {
            background-image: url("https://storage.googleapis.com/gen-atmedia/3/2015/12/972c8534d61a3b36e3d417581f570a2e60446696.jpeg");
            background-position: 50%;
            background-size: cover;
            width: 100%;
            height: 100%;
        }

        .left-side {
            position: relative;

            .container {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                width: 100%;
            }

            display: unset !important;
            width: 100%;
            height: 100%;
            background-color: black;

            button {
                color: white;
            }
        }

        position: fixed;
        z-index: 150;
        grid-column: 2;
        height: 100vh;
        width: 100%;
        h1 {
            color: #fff;
        }

        button {
            margin-top: 50px;
            justify-self: flex-end;
            font-family: inherit;
            border: none;
            color: #222;
            background-color: #fff;
            padding: 14px 24px;
            border-radius: 8px;
            max-width: 170px;
        }
    }

    .flex {
        img {
            border-radius: 50%;
            border: 1px lightgray solid;
            width: 3rem;
            object-fit: cover;
            aspect-ratio: 1/1;
        }

        li:nth-child(2) {
            margin-left: 1rem;
        }
    }

    .header {
        display: flex;
        justify-content: center;

        .reserve-button {
            width: 10rem;
        }
    }

    table {
        margin-top: 2rem;
        tr:not(first-child) {
            td {
                text-align: left;
            }
        }

        .guests {
            tr:first-child {
                td {
                    font-size: 0.8rem;
                    color: #50abae;
                }
            }
            tr {
                td {
                    text-align: left;
                    font-size: 0.7rem;
                    border: 0;
                }
            }
        }
    }

    th {
        font-family: Airbnb-Cereal-Bold;
    }

    .reservations-container {
        margin-top: 4rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 4rem;

        h1 {
            margin-bottom: 14px;
        }

        button {
            background-color: unset;
            border: 0.1px black solid;
            border-radius: 8px;
            padding: 8px;
            margin: 4px 0;
            font-family: Airbnb-Cereal-Medium;

            &:hover {
                background-color: rgb(226 226 226 / 46%);
            }
        }
        .reservations-mobile {
            display: none;

            .reseravtion-preview-mobile {
                border-bottom: 1px solid $clrGRAY;
                color: $clrGRAY;
                h1 {
                    color: lighten($clrBLACK, 10%);
                    font-size: 1.2rem;
                    margin-bottom: 0.25rem;
                }
                button {
                    margin: 0.5rem auto;
                }
            }
        }
    }

    tr,
    td,
    th,
    table {
        padding: 2px;
    }

    th {
        font-family: Airbnb-Cereal-Medium;
        text-align: left;
        font-size: 1rem;
        border-bottom: 0.3px $clrGRAY solid;
    }

    th:last-child,
    td:last-child {
        text-align: right;
    }

    table {
        border-collapse: collapse;
    }

    td {
        border-bottom: 0.3px $clrGRAY solid;
        text-align: left;
        font-size: 0.9rem;
    }

    .stock-margin-center {
        margin-top: 170px;
    }

    .host-img {
        margin: 3rem;
        width: 2rem;
    }
}
