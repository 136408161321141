.center {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.underline{
  text-decoration: underline;
}


.red {
  color:$clrRED !important
}
.center-h {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-h-between {
  margin: auto;
    display: flex;
    justify-content: space-between;
    align-items:center;
}


  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
  
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
  
    /* Support for IE. */
    font-feature-settings: 'liga';
  }

* {
  
    list-style-type: none !important;
    list-style: none !important;
    margin: 0;
    padding: 0;
  }

  .flex {
    display: flex;
  }

  .flex-row-space-btw {
    display: flex;
    justify-content: space-between;
  }

  .flex-col {
    display: flex;
    flex-direction: column;
  }
  .flex-col-space-around{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .cursor{
    cursor: pointer;
  }

  .stock-margin:not(.card-container){
    display: grid;
    // grid-template-columns: 60px 1fr 60px;
    >{
      grid-column: 2;
    }
    
  }
  .stock-margin-narrow {
    display: grid;
    grid-template-columns: minmax(4rem,1fr) minmax(auto,1120px) minmax(4rem,1fr);
  }

  .grid-3-col {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .undecorate {
    text-decoration: none;
    color: $clrBLACK
  }

  

  .stock-margin-center {
    grid-column: 2;
  }

  .fixed{
    position: fixed;
  }

  .img-cover {
    width: 100%;
    height: 70%;
    object-fit:cover;
    @include aspect-ratio(16, 9)
}

.border-radius {
border-radius: 12px;
}

.gray{
  color:$clrGRAY
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.clickable {
  cursor: pointer;
}

.screen{
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 99vw;
  opacity: 0;
  z-index: 1;
}

.line {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid rgb(221, 221, 221);
}