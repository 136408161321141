.statistics-container{
    padding: 0 20%;
    display:flex;
    justify-content: space-between;
    *{
        text-align: left;
        h1{

            font-family: Airbnb-Cereal-Bold;
        }
        h3{
            text-decoration: underline;
            font-size: 1rem;
            margin-bottom: 1rem;
        }
    }
}