.res-confirmed-modal{
    z-index: 110;
    .close-modal{
        position: fixed;
        right: 1rem;
        top: 1rem;
        border-radius: 50%;
        background-color: $bgc;
        opacity: 50%;
        &:hover{
            opacity: 100%;
            // background-color: $bgc;
        }

    }

    .bottom-container{
        padding: 1rem;

        .dates-container{
            margin-bottom: 0.5rem;
        }

        .guests, .address, .price{
            padding-bottom: 1rem;
        }
    }
    box-shadow: 0px 2px 14px -2px rgb(0 0 0 / 85%);
    border-radius: 12px;
    background-color: $bgc;
    width: 40%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    img{
        width: 100%;
        aspect-ratio: 16/9;
        object-fit: cover;
        border-top-left-radius : 12px;
        border-top-right-radius : 12px;
        
    }
    h1{
        font-size: 1.2rem;
        font-family: Airbnb-Cereal-Book;
        padding-bottom: 1rem;


    }

   
    h2{
        font-size: 1rem;
        font-family: Airbnb-Cereal-Bold;
    }
    h3{
        font-size: 0.9rem;
    }

    span{
        font-size: 2rem;
    }

    button{
        width: 100%;
        margin: 0;
    }


    .dates-container{
        justify-content: flex-start;
        gap: 1rem;
        display: flex;

        div:nth-child(2){
            border-left: 1px black solid;
            padding-left: 1rem;
        }
        div{
            width: 100%;
        }

        li{
            *{
            width: 100%;
            text-align: left !important;
        }
    }
    }

    .bottom-container{
        >div:last-child{
            display: flex;
            justify-content: center;
            align-items: center;
            button{
                width: 15rem;
            }
        }

       
    }


}