
.in-margin-container{
    color: $clrBLACK;
}

.card-container{
    z-index: 0;
    position: relative;
    margin-top: 1rem;
    display: grid;
    gap: 1.2rem;
    img {
        width: 100%;
        height: 70%;
        object-fit:cover;
        @include aspect-ratio(16, 9)
    }

    a {
        text-decoration: none

    }

    a, a:visited, a:hover, a:active {
        color: inherit;
      }
}

.no-stays-msg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}
