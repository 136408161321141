.main-footer{
    position: fixed;
    bottom: 0;
    width: 100%;

    border-top: 2px var(--verylightgray) solid;
    background-color: $headerbackgroundColor;
}

.footer-mobile{
    z-index: 10000000;
    *{
        text-decoration: none;
        color: black;
    }
    div{
        grid-column: 1/4;
        display: flex ;
        justify-content: space-around;
        padding: 0.5rem;
    }
}


.footer{
    grid-column: 2;


    bottom: 0;
    height: 2.9375rem;
    display: flex;
    justify-content: space-between;
    // padding: 5px;
    width: 100%;

    h1{
        font-size: 1rem;
        align-self: center;
    }
    
    li{
        align-self: center;
        font-family: Airbnb-Cereal-Book;
        list-style-type: none;
        list-style: none;
        font-family: Airbnb-Cereal-Book;
        span{
            align-self: center;
            font-size: 1rem;
        }
        
    }
    li{
        margin-right: 5px;

    }
    li:last-child{
        margin: 0;
    }
}

.left-side{
    display: flex;
    li{
       cursor: default; 
    }   
}
.right-side{
    display: flex;
}
