.user-menu-container {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: 1em;
    font-weight: 400;
    background-color: white;
    position: absolute;
    border-radius: 12px;
    right: 0;
    margin-top: 10px;
    box-shadow: 0px 2px 14px -2px rgba(0, 0, 0, 0.85);
    width: 240px;
    z-index: 15;
    font-family: Airbnb-Cereal-Light;
     li {
        a {
            padding: 1rem;
            display: flex;
            justify-content: center;
        }

        color: #222;
        color: $clrBLACK;
        text-decoration: none;
        margin: 0;
        justify-content: left;
        justify-items: start;
        display: flex;
        align-items: flex-start;
        a,
        p {
            text-align: left;
            width: 100%;
            height: 100%;
        }

        cursor: pointer;
        &:hover {
            background-color: $lightGreyBackgroundColor;
            border-radius: 12px;
        }
    }
}
