.stay-card {
    position: relative;
    border: 0px black solid;
    border-radius: 12px;
    text-align-last: auto;
    cursor: pointer;

    .img-container {
        position: relative;
    }


    &:hover .right-arr,
    &:hover .left-arr {
        // &:hover

        text-align: center;

        span {

            border-radius: 50%;
            padding: 10px;
            background-color: rgb(255, 255, 255);
            visibility: visible;
            text-align: center;
        }
    }

    .arr {
        visibility: hidden;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: fit-content;

        span {
            font-size: 10px;
        }
    }

    .right-arr {
        right: 2px;
    }

    .left-arr {
        left: 2px;
    }

    .carousel *{
        border-radius: 12px;
    }

    .carousel.carousel-slider {
        .control-next {
            &:before {
                margin: 0px 4px 5px 6px;
                border-left: 6px solid #222;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
            }
        }

        .control-prev {
            &:before {
                margin: 0px 6px 5px 4px;
                border-right: 6px solid #222;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
            }
        }

        &:hover .control-arrow {
            opacity: .4;
        }

        .control-arrow {
            border-radius: 50% !important;
            border: 1px solid rgba(0, 0, 0, .08) !important;
            outline: none !important;
            margin: auto 15px !important;
            padding: 0 !important;
            color: #222 !important;
            background-color: hsla(0, 0%, 100%, .9) !important;
            box-shadow: 0 0 0 1px transparent, 0 0 0 4px transparent, 0 2px 4px rgba(0, 0, 0, .18) !important;
            width: 32px !important;
            height: 32px !important;
            opacity: .0;

            &:hover {
                opacity: 1;
            }
        }
    }

    h1 {
        font-size: 15px;
        overflow: clip;
        font-weight: 100;
        color: #222;
        text-overflow: ellipsis;

    }


    img {
        border-radius: 12px;
        aspect-ratio: 1/1;
    }

    span {
        font-size: 12px;
    }

    .stay-preiview-details {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;

    }

    .location-rate {
        font-family: Airbnb-Cereal-Book, sans-serif;

        li {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    li {
        list-style: none;
        white-space: nowrap;
        overflow: hidden;
    }

    .propery-price {
        li {
            display: flex;



            span {
                font-size: 15px;
                font-family: Airbnb-Cereal-Light;
            }
        }
    }

    .propery-description {
        margin-top: 5px;

        h1 {
            font-family: Airbnb-Cereal-Light, sans-serif;
            color: $lite-font-color;
        }
    }
}

.heart {
    position: absolute;
    top: 4%;
    right: 4%;
    z-index: 2;
    opacity: 0.85;
    span {
        font-size: 30px;
    }
}

.heart-border{
    position: absolute;
    top: 4%;
    right: 4%;
    z-index: 2;
    opacity: 0.85;
    span {
        font-size: 34px;
    }
}
