.date-range-selector {
    display: flex;
    justify-content: space-around;
    .DateRangePickerInput {
        border: unset;
    }

    .DateRangePickerInput_1 {
        display: flex;
        border-radius: 12px;
        background-color: unset !important;
        background: unset;
        outline: none !important;
        -webkit-appearance: none !important;
        text-align: center;
        justify-content: center;
    }

    .DateInput_input {
        background-color: unset !important;
    }
    .DateRangePickerInput_arrow {
        display: none;
        align-self: center;
        width: 40px;
    }
    .DateInput {
        background: unset;
        justify-content: center;
        text-align: center;
    }
}

.guests-pick {
    display: flex;
    justify-content: space-between;
    div {
        gap: 1rem;

        h4,
        h3 {
            font-size: 1rem;
        }
    }
    .cursor {
        font-size: 25px;
    }
    font-size: 19px;
    padding: 20px;
    border-top: 1px solid #b0b0b0;
}
.picker-container {
    border: 1px solid $clrGRAY;
    width: 100%;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.reserve-stay-container{
    .DateRangePickerInput_1 {
    padding:10px;
}
}


.DateInput_input {
    text-align: center;
    padding: 0 !important;
}


.DateInput_input__focused {
    border-bottom: 2px solid $clrRED !important;
}
