.home-page-container {
    .become-a-host{
    
        margin-top: 8rem;
        background-image: url("https://res.cloudinary.com/dhy6ndeij/image/upload/v1654118736/Become-a-host_fyktkk.png") ;
        background-position: 50%;
        background-size: cover;
        height: 90vh;
        width: 100%;
    
        padding-left: 2.3rem;
        padding-top: 5rem;
        
        h1{
            color: #fff;
        font-size: 48px;
        
        }
    
        button{
            margin-top: 50px;
        justify-self: flex-end;
        font-family: inherit;
        border: none;
        color: #222;
        background-color: #fff;
        padding: 14px 24px;
        border-radius: 8px;
        max-width: 170px;
        }
    }

    .main-page-head {
        background-image: url('../../assets/imgs/home-main.webp');
        background-size: cover;
        background-position: 50%;
        height: 80vh;
        width: 100%;
        z-index: -10;
    }

    .home-filterr {
        top: 62px;
    }
}

.hero {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    div {
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }

    h1 {
        font-size: 2rem;
        color: $clrRED;
        // background-color: white;
        // padding: 10px;
        // border-radius: 10px;
        font-family: Airbnb-Cereal-Book;
    }

    button {
        cursor: pointer;
        margin: 0 auto;
        // margin-bottom: 150px;
        -webkit-tap-highlight-color: transparent !important;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        background: #fff;
        border-radius: 1000px;
        box-shadow: 0 6px 16px rgba(0, 0, 0, .12) !important;
        border: none;

        h3 {
            margin: 0;
            padding: 16px 32px;
            font-size: 18px;
            font-family: inherit;
            background: -webkit-linear-gradient(90deg, #6f019c, #c6017e 135.12%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

    }
}

.home-page-card {
    a {
        color: $clrBLACK;
        text-decoration: none;
    }
}

.home-page-card-container {
    h1 {
        font-size: 2rem;
        margin-bottom: 1rem;
        margin: 1rem 0;
        font-family: Airbnb-Cereal-Book;
    }

    cursor: pointer;
    display: flex;
    flex-direction: column;

    .home-page-card-line {
        h1 {
            font-size: 1rem;
        }

        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        justify-content: space-between;
        gap: 1.5625rem;

        img {
            aspect-ratio: 1/1;
            border-radius: 12px;
        }

        .img-cover {
            height: unset;
        }
    }

}
