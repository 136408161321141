.trip-card-container {
    display: flex;
    padding: 1rem;
    padding-left: 0;

}

.trip-cards-container {
    display: grid;
}


.trip-card {
    display: grid;
    grid-template-columns: 1fr 3fr auto;
    column-gap: 1rem;

    div {
        width: 100%;
    }
}

.trip-card-text {
    display: flex;
    flex-direction: column;
    justify-content: center;



    div:first-child {
        font-size: 1rem;
        font-family: Airbnb-Cereal-Medium;
        color: $clrBLACK;
    }

    div:not(first-child) {
        font-size: 0.9rem;
        font-family: Airbnb-Cereal-Book;
        color: #717171
    }

    display: flex;
    flex-direction: column;

}

.trip-card img {
    border-radius: 12px;
    width: 6.8125rem;
    aspect-ratio: 1/1;
    object-fit: cover;
    

}

.trip-card-name {
    font-family: Airbnb-Cereal-Bold;
}

