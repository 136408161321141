* {
    box-sizing: border-box;
    margin: 0;
    font-family: Airbnb-Cereal-light;
    // font-weight: 100;
    
}
html { margin-bottom: 150px }

.material-icons{
    display: flex;
}

h1 h2 a{
    text-decoration: none;
      color: inherit;
}


body {
    background-color: $bgc;
    // font-family: Cereal;
.app{
    // margin-bottom: 6.9375rem
}


}

.font-light{
    font-family: Airbnb-Cereal-Light !important;
}
.font-bold{
    font-family: Airbnb-Cereal-Bold !important;
}
.font-bolder{
    font-family: Airbnb-Cereal-Extra-Bold !important;
}
.font-medium{
    font-family: Airbnb-Cereal-Medium !important;
}

.loader {
    // width: 8vmax;
    // height: 8vmax;
    border-right: 6px solid $clrRED;
    border-radius: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    margin: auto;
    animation: spinRight 800ms linear infinite;
    
    &:before, &:after {
      content: '';
      width: 6vmax;
      height: 6vmax;
      display: block;
      position: absolute;
      top: calc(50% - 3vmax);
      left: calc(50% - 3vmax);
      border-left: 3px solid $clrRED;
      border-radius: 100%;
      animation: spinLeft 800ms linear infinite;
    }
    
    &:after {
      width: 4vmax;
      height: 4vmax;
      top: calc(50% - 2vmax);
      left: calc(50% - 2vmax);
      border: 0;
      border-right: 2px solid $clrRED;
      animation: none;
    }
  }
  
  @keyframes spinLeft {
    from {transform:rotate(0deg);}
    to {transform:rotate(720deg);}
  }
  
  @keyframes spinRight {
    from {transform:rotate(360deg);}
    to {transform:rotate(0deg);}
  }
  



