:root {
    --headerFontColor: #fff;
    --headerbackgroundColor: #fff;
    --verylightgray: #ECECEC;
    --mouse-x:1;
    --mouse-y:1;
    --bgc : #F7F7F7;
    --ResVisible: block;
    
}

$headerbackgroundColor: #fff;
$lightGreyBackgroundColor: rgb(242, 242, 242);
$clrRED: #FE385C;
$clrBLACK: #222222;
$clrGRAY : #B0B0B0;
$bgc : #F7F7F7;
$lite-font-color: #717171;
$headerFontColor: #B0B0B0;

